import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";

import MovieList from "./MovieList";
import MovieDetail from "./MovieDetail";

// API KEY a1eea0fa6de140ca08af12d9fac6df18
// https://api.themoviedb.org/3/discover/movie?api_key=a1eea0fa6de140ca08af12d9fac6df18&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Link to="/">
            <img src={logo} className="App-logo" alt="logo" />
          </Link>
        </header>
        <Switch>
          <Route exact path="/" component={MovieList}></Route>
          <Route path="/:id" component={MovieDetail} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
