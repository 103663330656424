import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Movie from "./Movie";

// API KEY a1eea0fa6de140ca08af12d9fac6df18
// https://api.themoviedb.org/3/discover/movie?api_key=a1eea0fa6de140ca08af12d9fac6df18&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1

function MovieList() {
  const [movies, setMovies] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(
          "https://api.themoviedb.org/3/discover/movie?api_key=a1eea0fa6de140ca08af12d9fac6df18&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1"
        );
        const movies = await res.json();
        setMovies(movies.results);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);

  return (
    <MovieGrid>
      {movies.map(movie => (
        <Movie key={movie.id} movie={movie} desc={movie.desc} />
      ))}
    </MovieGrid>
  );
}

export default MovieList;

const MovieGrid = styled.div`
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 1rem;
`;
