import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Overdrive from "react-overdrive";
import { Poster } from "./Movie";

const POSTER_PATH = `http://image.tmdb.org/t/p/w154`;
const BACKDROP_PATH = `http://image.tmdb.org/t/p/w1280`;

// API KEY a1eea0fa6de140ca08af12d9fac6df18
// https://api.themoviedb.org/3/discover/movie?api_key=a1eea0fa6de140ca08af12d9fac6df18&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1

function MovieDetail({ match }) {
  const [movie, setMovie] = useState({});
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(
          `https://api.themoviedb.org/3/movie/${match.params.id}?api_key=a1eea0fa6de140ca08af12d9fac6df18&language=en-US`
        );
        const movie = await res.json();
        setMovie(movie);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [match.params.id]);

  const {
    poster_path,
    backdrop_path,
    title,
    release_date,
    overview,
    id
  } = movie;
  return (
    <MovieWrapper backdrop={`${BACKDROP_PATH}${backdrop_path}`}>
      <MovieInfo>
        <Overdrive id={id}>
          <Poster src={`${POSTER_PATH}${poster_path}`} alt={title} />
        </Overdrive>
        <div>
          <h1>{title}</h1>
          <h3>{release_date}</h3>
          <p>{overview}</p>
        </div>
      </MovieInfo>
    </MovieWrapper>
  );
}

export default MovieDetail;

const MovieWrapper = styled.div`
  position: relative;
  padding-top: 50vh;
  background: url(${({ backdrop }) => backdrop}) no-repeat;
  background-size: cover;
`;

const MovieInfo = styled.div`
  background: white;
  text-align: left;
  padding: 2rem 10%;
  display: flex;
  > div {
    margin-left: 20px;
  }
  img {
    position: relative;
    top: -5rem;
  }
`;
